import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Welcome to Adam Smyer's website.</h1>
    <p>
      Adam's novel <span style={{ fontStyle: `italic` }}>Knucklehead</span>{" "}
      (Akashic Books), was the sole title shortlisted for the 2018 Ernest J.
      Gaines Award for Literary Excellence.
    </p>
    <p>
      His second book,{" "}
      <span style={{ fontStyle: `italic` }}>
        You Can Keep That to Yourself: A Comprehensive List of What Not to Say
        to Black People, for Well-Intentioned People of Pallor
      </span>{" "}
      (Akashic Books), was released on September 1, 2020.
    </p>
    <div className="responsive-container">
      <iframe
        className="responsive-iframe"
        id="ytplayer"
        type="text/html"
        width="640"
        height="360"
        src="https://www.youtube-nocookie.com/embed/dmg7BIxC7zs?rel=0"
        frameborder="0"
        allow="fullscreen"
        title="PSA: What NOT to say to Black people"
      ></iframe>
    </div>
    <section className="store-list">
      <p>
        <span style={{ fontStyle: `italic` }}>
          You Can Keep That to Yourself
        </span>{" "}
        is available from many online and local stores, including the following:
      </p>
      <ul>
        <li>
          <a
            href="http://www.akashicbooks.com/catalog/you-can-keep-that-to-yourself/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Akashic Books
          </a>
        </li>
        <li>
          <a
            href="https://www.amazon.com/Keep-That-Yourself-Comprehensive-Well-Intentioned/dp/1617758965/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Amazon
          </a>
        </li>
        <li>
          <a
            href="https://www.barnesandnoble.com/w/you-can-keep-that-to-yourself-adam-smyer/1136382238"
            target="_blank"
            rel="noopener noreferrer"
          >
            Barnes & Noble
          </a>
        </li>
        <li>
          <a
            href="https://play.google.com/store/books/details/Adam_Smyer_You_Can_Keep_That_to_Yourself?id=6OneDwAAQBAJ"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Play
          </a>
        </li>
        <li>
          <a
            href="https://www.greenlightbookstore.com/book/9781617758966"
            target="_blank"
            rel="noopener noreferrer"
          >
            Greenlight Bookstore
          </a>
        </li>
        <li>
          <a
            href="https://www.indiebound.org/book/9781617758966"
            target="_blank"
            rel="noopener noreferrer"
          >
            IndieBound (independent local US bookstores)
          </a>
        </li>
        <li>
          <a
            href="https://www.kobo.com/us/en/ebook/you-can-keep-that-to-yourself-1"
            target="_blank"
            rel="noopener noreferrer"
          >
            Kobo
          </a>
        </li>
        <li>
          <a
            href="https://www.penguinrandomhouse.com/books/665338/you-can-keep-that-to-yourself-by-adam-smyer/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Penguin Random House (audiobook)
          </a>
        </li>
      </ul>
    </section>
  </Layout>
)

export default IndexPage
